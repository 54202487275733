<template>
  <div class="setting" v-padding:top="46">
    <van-nav-bar title="电子档案" fixed> </van-nav-bar>
    <van-cell-group>
      <van-cell
        v-for="(item, index) in cellList"
        :key="index"
        :title="item.name"
        is-link
        @click="onCellClick(item.url)"
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      cellList: [
        {
          name: "基本信息",
          url: "setting-detail",
        },
        {
          name: "排污许可",
          url: "setting-blowoff",
        },
        {
          name: "应急预案",
          url: "setting-emergency",
        },
        {
          name: "环评信息",
          url: "setting-eiainfo",
        },
        {
          name: "监测验收",
          url: "setting-monitorinspected",
        },
        {
          name: "运维企业申报",
          url: "setting-operation",
        },
        {
          name: "一企一档申报",
          url: "setting-enterprise",
        },
        {
          name: "站点信息申报",
          url: "setting-point",
        },
        {
          name: "其他补充资料",
          url: "setting-supplementary",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 单元格点击
    onCellClick(path) {
      // let path = "";
      // switch (name) {
      //   // 基本信息
      //   case "detail":
      //     path = "setting-detail";
      //     break;
      //   // 排污许可
      //   case "blowoff":
      //     path = "setting-blowoff";
      //     break;
      //   // 应急预案
      //   case "emergency":
      //     path = "setting-emergency";
      //     break;
      //   // 环评信息
      //   case "eiainfo":
      //     path = "setting-eiainfo";
      //     break;
      //   // 监测验收
      //   case "monitorinspected":
      //     path = "setting-monitorinspected";
      //     break;
      //   // 其他补充资料
      //   case "supplementary":
      //     path = "setting-supplementary";
      //     break;
      // }
      this.$router.push({
        path,
        query: {},
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
